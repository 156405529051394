import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment/src/moment';

export default function useCommentsList() {

    // Use toast
    const toast = useToast();

    const refCommentListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'product.name', sortable: true, label: 'Producto' },
        { key: 'user', sortable: true, label: 'Usuario' },
        { key: 'rating', sortable: true, label: 'Rating' },
        { key: 'title', sortable: true, label: 'Título' },
        { key: 'description', sortable: true, label: 'Descripción' },
        { key: 'createdAt', sortable: true, label: 'Fecha' },
        { key: 'isAccepted', sortable: true, label: 'Estado' },
        { key: 'actions', label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalComments = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('createdAt');
    const isSortDirDesc = ref(true);
    const filterStartDate = ref(null);
    const filterState = ref(null);
    const filterProduct = ref(null);
    const filterSD = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refCommentListTable.value ? refCommentListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalComments.value,
        }
    });

    const refetchData = () => {
        refCommentListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, filterStartDate, filterState, filterProduct], () => {
        filterSD.value = moment(filterStartDate.value, "DD-MM-YYYY").toISOString();
        refetchData();
    });

    const fetchComments = (ctx, callback) => {

        /*   let filterProductArray = [];
          if (filterProduct.value) {
              filterProduct.value.map(item => {
                  filterProductArray.push(item.value);
              });
          } */

        store
            .dispatch('app-comment/fetchComments', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                filterStartDate: filterSD.value ? filterSD.value.split('T')[0] : null,
                filterState: filterState ? filterState.value : null,
                filterProduct: filterProduct ? filterProduct.value : null
            })
            .then(response => {
                const { comments, total } = response.data;
                callback(comments);
                totalComments.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de comentarios.',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchComments,
        tableColumns,
        perPage,
        currentPage,
        totalComments,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCommentListTable,

        refetchData,
        // Extra Filters
        filterState,
        filterProduct,
        filterStartDate,
    }
}