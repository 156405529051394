<template>

	<div>
		
		<b-card>
			<b-row>					
				<b-col cols="12" class="mb-1">
					<h5>FILTROS</h5>
				</b-col>
				
				<b-col sm="12" md="6">
					<label>Producto</label>
					<v-select 
						v-model="filterProduct"						
						:options="optionsProducts" 
					/>
				</b-col>
				<b-col sm="12" md="3">
					<label>Estado</label>
					<v-select 						
						v-model="filterState"
						:options="optionsStates" />
				</b-col>
				
			    <b-col sm="12" md="3">
                    <label>Fecha</label>
                     <flat-pickr
                        v-model="filterStartDate"
                        class="form-control"
                        :config="config"
                    />
                </b-col>		
			</b-row>
		</b-card>
		
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
													   
					<!-- Search -->
					<b-col 
						cols="12"
						md="5"
						class="ml-auto"
					>
						<div class="d-flex ">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar commentario ..."
								trim
							/>
						</div>
					</b-col>
				</b-row>

			</div>

			<b-table
				ref="refCommentListTable"
				class="position-relative"
				:style="totalComments < 3 && totalComments > 0 ? 'height: 250px' : 'height: inherit'"
				:items="fetchComments"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc">
                 
                 
                <template #cell(user)="data">
                     {{ data.item.user.names }}
				</template>
                
				<template #cell(rating)="data">                     
					<div class="item-meta">
						<ul class="unstyled-list list-inline mb-25">
							<li
								v-for="star in 5"
								:key="star"
								class="ratings-list-item"
							>
								<feather-icon
									icon="StarIcon"
									size="18"
									class="mr-25"
									:class="[{'fill-current': star <= data.item.rating}, star <= data.item.rating ? 'text-warning' : 'text-muted']"
								/>
							</li>
						</ul>
					</div>
				</template>
								
				<template #cell(description)="data">
					{{ data.item.description.substring(0,20) + "..." }}
				</template>
				
                <template #cell(createdAt)="data">
					{{ formatDate(data.item.createdAt) }}
				</template>
				
                <template #cell(isAccepted)="data">
					
					<b-button v-if="data.item.isAccepted == 0"
						v-ripple.400="'rgba(234, 84, 85, 0.15)'"
						variant="flat-danger"
					>
						<feather-icon
							icon="XIcon" 
							class="mr-50"
						/>
						<span class="align-middle">Rechazado</span> 						  	
					</b-button>
					
					<b-button v-else-if="data.item.isAccepted == 1"
						v-ripple.400="'rgba(40, 199, 111, 0.15)'"
						variant="flat-success"
					>
						<feather-icon
							icon="CheckIcon" 
							class="mr-50"
						/>
						<span class="align-middle">Aceptado</span> 
					</b-button>

					<b-button v-else
						v-ripple.400="'rgba(255, 159, 67, 0.15)'"
						variant="flat-warning"
					><feather-icon
							icon="AlertCircleIcon" 
							class="mr-50"
						/>
						<span class="align-middle">Por evaluar</span> 						 
					</b-button>
				</template>
                
                
				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>
						
						<b-dropdown-item @click="viewComment(data.item._id)">
							<feather-icon icon="EyeIcon" />
							<span class="align-middle ml-50">Ver</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteComment(data.item._id)">
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>
			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalComments"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
        
         <b-modal
            ref="modalComment"
            title="Comentario del producto"
            centered     
            ok-title="Actualizar"
            ok-variant="outline-primary"
        >
            <b-form @submit.prevent>
                <b-row>
					
					<!-- date -->
                    <b-col cols="12">
                        <b-form-group
                            label="Fecha"
                            label-for="vi-date"
                        >
                            <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="CalendarIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                    id="vi-date"
                                    type="text"
                                    v-model="date"
                                    disabled
                                    readonly
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
										
					<!-- product -->
                    <b-col cols="12">
                        <b-form-group
                            label="Producto"
                            label-for="vi-product"
                        >
                            <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="ShoppingCartIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                    id="vi-product"
                                    type="text"
                                    v-model="productName"
                                    disabled
                                    readonly
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
										
                    <!-- rating -->
                    <b-col cols="12">
						<label> Rating</label>
						<div class="item-meta">
							<ul class="unstyled-list list-inline mb-25">
								<li
									v-for="star in 5"
									:key="star"
									class="ratings-list-item"
								>
									<feather-icon
										icon="StarIcon"
										size="18"
										class="mr-25"
										:class="[{'fill-current': star <= rating}, star <= rating ? 'text-warning' : 'text-muted']"
									/>
								</li>
							</ul>
						</div>
                    </b-col>
					
                    <!-- Names -->
                    <b-col cols="12 mt-1">
                        <b-form-group
                            label="Nombres"
                            label-for="vi-first-name"
                        >
                            <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="UserIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                    id="vi-first-name"
                                    v-model="names"
                                    disabled
                                    readonly
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    
                    <!-- title -->
                    <b-col cols="12">
                        <b-form-group
                            label="Título"
                            label-for="vi-title"
                        >
                            <b-form-input
                                id="vi-title"
                                type="text"
                                v-model="title"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>
                    
                    <!-- description -->
                    <b-col cols="12">
                        <b-form-group
                            label="Descripción"
                            label-for="vi-description"
                        >
                             <b-form-textarea
                                id="vi-description"
                                v-model="description"
                                rows="4"
                                disabled
                                readonly
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col>		
					
					<!-- image -->
                    <b-col cols="12" v-if="image">
                        <b-form-group
                            label="Imagen"
                            label-for="vi-image"
                        >
							 <b-img
								thumbnail
								fluid
								:src="transformImg(image)"
							/>
                        </b-form-group>
                    </b-col>			
					
					<b-col cols="12" class="text-center">
						<hr>
						<h5>Evaluar comentario</h5>
						<b-row align-h="around" class="my-2">
							<b-col cols="4">								
								<b-form-radio
									v-model="isAccepted"
									value="1"
									:checked="isAccepted == 1 ? true : false" 
									class="custom-control-success"
								>
									Aceptar
								</b-form-radio>
							</b-col>
							<b-col cols="4">								
								<b-form-radio
									v-model="isAccepted"
									value="0"
									:checked="isAccepted == 0 ? true : false" 
									class="custom-control-danger"
								>
									Rechazar
								</b-form-radio>
							</b-col>
						</b-row>						
					</b-col>					
                </b-row>
            </b-form>
			
			
			<template #modal-footer="{ cancel }">

                <b-button
                    variant="primary"
                    class="float-right"
                    @click="updateComment()"
                >
                    Evaluar
                </b-button>

                <b-button variant="outline-secondary" @click="cancel()">
                    Cancelar
                </b-button>
            </template>
			
        </b-modal>
	</div>
</template>

<script>

	import {BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormDatepicker} from 'bootstrap-vue';
	import { useToast } from 'vue-toastification/composition';
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import store from '@/store';
    import vSelect from 'vue-select';
	import VueSweetalert2 from 'vue-sweetalert2';    
	import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
	import { onUnmounted, ref, reactive } from '@vue/composition-api';
	import useCommentsList from './useCommentsList';
	import Ripple from 'vue-ripple-directive'
	import commentStoreModule from '../commentStoreModule';
	import Vue from 'vue';    
	import axios from 'axios';    
	import 'animate.css';
    import moment from 'moment';
	
	Vue.use(VueSweetalert2);

	export default {
		components: {
			BCard,
			BRow,
			BCol,
			BFormInput,
			BButton,
			BTable,
			BMedia,
			BAvatar,
			BLink,
			BBadge,
			BDropdown,
			BDropdownItem,
			BPagination,
			BFormDatepicker,	
            
            // 3rd party
            vSelect,
            flatPickr,
            moment
		},
		directives: {
			Ripple,
		},
		data() {                                   
			return {
				PublicUrl: process.env.VUE_APP_HOST,
                
                /* Campos de Formulario */
				idComment: null,
                names: null,
                rating: null,
                date: null,
				productId: null,
                productName: null,
                title: null,
                image: null,
                description: null,   
				isAccepted: null,
                config: {
                    enableTime: false, 
                    dateFormat: 'd/m/Y',
                    locale: Spanish
                },             
			}
		}, 
		methods: {
			transformImg (img){
                if (img){
                    if (img.includes('http') || img.includes('https')){
                        return img;
                    } else {
                        return require(`@/assets/images/modules/products/${img}`);
                    }
                } else {
                    return null;
                }
            },
			formatDate (date){
				return moment(date).format('DD/MM/YYYY HH:mm:ss');
			},
            viewComment(id) {
                store.dispatch('app-comment/fetchComment', { id })
                .then( response => {
                    if(response.data){
						console.log(response)
						this.idComment = response.data._id
						this.productName = response.data.product.name
                        this.names = response.data.user.names + ' ' + response.data.user.surnames
                        this.rating = response.data.rating
                        this.date = this.formatDate(response.data.createdAt)
                        this.title = response.data.title
                        this.description = response.data.description
						this.image = response.data.image
                        this.isAccepted = response.data.isAccepted
						
                        this.$refs['modalComment'].show()
                    }
                })
            },
			
			updateComment(){
                
                let formData = {        
					productId: this.productId,            					
					isAccepted: this.isAccepted
                }
				
                store.dispatch('app-comment/updateComment', { id: this.idComment, formData })	
                .then( (response) => {    
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    });                    
                    this.$refs.refCommentListTable.refresh();
					this.$refs['modalComment'].hide()
                })
                .catch( (err) => {
                    let message = err.response.data.message ? err.response.data.message : 'Error al actualizar el formulario.';
                    
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });
                });
            },
		},
		setup() {

			// Use toast
			const toast = useToast();

			const USER_APP_STORE_MODULE_NAME = 'app-comment';

			// Register module
			if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, commentStoreModule);

			// UnRegister on leave
			onUnmounted(() => {
				if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
			});
			
			const optionsProducts = reactive([]);
			
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/products`)
			.then(response => {
				if(response){
					response.data.all.map( item => {
						optionsProducts.push({
							label : item.name, 
							value : item._id
						});
					});
				}
			});
			
			const optionsStates = [
				{ label: "Rechazado", value: 0},
				{ label: "Aceptado", value: 1},
				{ label: "Por evaluar", value: 2},
			];
			
			const deleteComment = (id) => {
		
					Vue.swal({
						title: '¿Estás seguro de eliminar el comentario?',
						text: "¡Si no lo está, puede cancelar la acción!",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: 'Sí, eliminar!',
						cancelButtonText: 'Cancelar',
						customClass: {
							confirmButton: 'btn btn-primary',
							cancelButton: 'btn btn-outline-danger ml-1'
						},
						showClass: {
							popup: 'animate__animated animate__tada'
						},
						buttonsStyling: false
					}).then(result => {
						if (result.value) {
							store.dispatch('app-comment/deleteComment', { id })
								.then( (response) => {
									refetchData();
									toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'CheckIcon',
											variant: 'success'
										}
									});
								})
								.catch( () => {
									toast({
										component: ToastificationContent,
										props: {
											title: 'Error al eliminar el comentario',
											icon: 'AlertTriangleIcon',
											variant: 'danger'
										}
									});
								});
						}
					});
			};
			
			const {
				fetchComments,
				tableColumns,
				perPage,
				currentPage,
				totalComments,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refCommentListTable,
				refetchData,
				
				filterStartDate,
				filterState,
        		filterProduct,
				
			} = useCommentsList();
			
			return {
				fetchComments,
				tableColumns,
				perPage,
				currentPage,
				totalComments,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refCommentListTable,
				refetchData,
				
				// Filter
				deleteComment,
				
				optionsStates,
				optionsProducts,				
				filterStartDate,
				filterState,
        		filterProduct,
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
		align-items: center;
	}

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>